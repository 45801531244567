import React, { useState } from 'react';

interface NamesContextProps {
  namesPicker: NamesProps;
  setNames: any;
}

interface NamesProps {
  title: string;
  names: string[];
  sortition: SortitionProps;
}

interface SortitionProps {
  winnersNumber: number;
  substitutesNumber: number;
  names: string[];
}

export const NamesContext = React.createContext<NamesContextProps>(
  {} as NamesContextProps,
);

export const NamesProvider = (props: any) => {
  const [namesPicker, setNames] = useState<NamesProps>({
    title: '',
    names: [],
    sortition: {
      winnersNumber: 0,
      substitutesNumber: 0,
      names: [],
    },
  });

  const { children } = props;
  return (
    <NamesContext.Provider value={{ setNames, namesPicker }}>
      {children}
    </NamesContext.Provider>
  );
};
