/* eslint-disable camelcase */
import React from 'react';
import usePersistedState from './helpers/usePersistedState';

interface FbProps {
  fb_connect: boolean;
  isToken: string;
  user_connect: string;
  user_id: string;
}
interface Settings {
  drawsPerClick: unknown;
  hashtags: string;
  samePersonMoreThanOnceAllowed: boolean;
  doubleCommentsAllowed: boolean;
  moreThanOneCommentperPerson: boolean;
}

interface LocalStorageProps {
  username: { username: string };
  email: { email: string };
  fb: FbProps;
  drawSettings: Settings;
  handleChangeUsername: (newUsername: string) => any;
  handleChangeEmail: (newEmail: string) => any;
}

export const LocalStorageContext = React.createContext<LocalStorageProps>(
  {} as LocalStorageProps,
);

export const LocalStorageProvider = (props: any) => {
  const [username, setUsername] = usePersistedState('@Instasorteio:username', {
    username: '',
  });

  const [email, setEmail] = usePersistedState('@Instasorteio:email', {
    email: '',
  });

  /* eslint-disable-next-line */
  const [fb, setFb] = usePersistedState<FbProps>('@Instasorteio:fb', {
    fb_connect: false,
    isToken: '',
    user_connect: '',
    user_id: '',
  });

  /* eslint-disable-next-line */
  const [drawSettings, setDrawSettings] = usePersistedState<Settings>(
    '@instasorteio:post_setup',
    {
      drawsPerClick: '1',
      hashtags: '',
      samePersonMoreThanOnceAllowed: false,
      doubleCommentsAllowed: false,
      moreThanOneCommentperPerson: false,
    },
  );

  const handleChangeUsername = (newUsername: string) => {
    setUsername({ username: newUsername });
  };

  const handleChangeEmail = (newEmail: string) => {
    setEmail({ email: newEmail });
  };

  const { children } = props;
  return (
    <LocalStorageContext.Provider
      value={{
        username,
        email,
        fb,
        drawSettings,
        handleChangeUsername,
        handleChangeEmail,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};
