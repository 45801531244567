/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles, createStyles } from '@material-ui/core/styles';
import background from '../assets/textura.svg';
import theme from './theme';
// import backgroundLove from '../assets/background-love.png';

const useStyles = makeStyles(() =>
  createStyles({
    paperStyles: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
    },
    logoInit: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
      '& img': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          height: 150,
        },
      },
    },
  }),
);

export default useStyles;
