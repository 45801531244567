export default {
  selectLanguage: 'Selecione o Idioma',
  languages: {
    portuguese: 'Português',
    english: 'Inglês',
  },
  errors: {
    pagarme: {
      e1011: 'Informações do Número do Cartão, CVV ou Validade inválida.',
      e1004:
        'Cartão bloqueado pelo banco emissor. Contate o banco/emissor do cartão.',
      e1000:
        'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor. Contate o banco/emissor do cartão.',
      e1016: 'Saldo insuficiente.',
      e1019:
        'Sistema de prevenção do banco não autorizou a compra. Contate o banco/emissor do cartão.',
      e2000:
        'Transação recusada pelo banco, contate o banco/emissor do cartão.',
    },
  },
  menu: {
    works: 'Como Funciona?',
    draw: 'Realizar Sorteio',
    contact: 'Fale conosco',
    copyright1: 'Copyright 2021.',
    copyright2: 'Todos os Direitos Reservados.',
    terms: 'Termos de Uso.',
    privacy: 'Política de Privacidade',
    lang: 'Selecione o Idioma',
    followTitle: 'Siga a gente!',
    follow: 'Seguir',
    next: 'Continuar',
    home: 'Início',
    ourApps: 'Nossos Sorteios',
  },
  labels: {
    postURL: 'Link do post',
  },
  texts: {
    pass: 'Senha',
    invalidPass: 'Senha inválida',
    invalidEmail: 'E-mail inválido',
    hint: 'Dica!',
    listItemDigInfluencer1: 'Ter no mínimo 300 mil seguidores.',
    listItemDigInfluencer2:
      'Envie seu email e a URL do seu sorteio para o nosso Instagram @instasorteioo através do seu perfil.',
    listItemDigInfluencer3:
      'Aguarde o seu sorteio ser  autorizado (24 horas úteis).',
    posts: 'Publicações',
    and: ' e ',
    clouldLoadingBenefits:
      'Clique em detalhes e aproveite os benefícios que reservamos para você!',
    previousPrice: 'R$ 34,90',
    discountPercent: '15%',
    total: 'Total:',
    discount: 'Desconto:',
    value: 'Valor:',
    version: 'versão',
    textShareWinner:
      'Poste no Instagram e marque a página do @instasorteioo que a gente reposta o seu resultado no nosso perfil oficial!',
    textSlowLoading: 'Com o Sorteio Premium você recebe isso e muito mais! ',

    exceededCommentsItem1: 'Sem limite de comentários para sortear',
    exceededCommentsItem2: 'Execução do sorteio totalmente em nuvem',
    exceededCommentsItem3: 'Acesso instantâneo aos sorteios em carregamento',
    exceededCommentsItem4: 'Conexão privilegiada com Instagram',

    slowLoadingListItem1: 'Execução do sorteio totalmente em nuvem;',
    slowLoadingListItem2: 'Acesso instantâneo aos sorteios em carregamento;',
    slowLoadingListItem3: 'Sem limite de comentários para sortear',
    slowLoadingListItem4: 'Conexão privilegiada com Instagram.',

    reviewWhatYouAreAquiring: 'Revise o que você está adquirindo',
    clouldUpload: 'Carregamento na núvem',
    commentsLoaded: ' comentários carregados',
    lumpSumPayment: 'Pagamento à vista sem mensalidades',
    payAnytimeWithPix: 'Pague com o Pix a qualquer hora.',
    instantFastAndSafe: 'É tudo instantâneo, rápido e seguro',
    howPixWorks1:
      'Abra o app da sua instituição financeira e entre no ambiente PIX.',
    howPixWorks2:
      'Aponte a câmera do seu celular do e escaneie o código QR o lado.',
    howPixWorks3: 'Confirme as informações do pagamento e finalize sua compra.',
    copyPixCode: 'Copiar código do Pix',
    camera: 'Aponte sua câmera aqui',
    waitingForPayment:
      'Estamos aguardando seu pagamento, Mas não se preocupe, as vezes pode demorar até 30 minutos para reconhecer a transferência.',
    youAreAt: 'Você está em ambiente um',
    aSafeEnvironment: 'ambiente seguro',
    pagarmeIsProcessing:
      'Pagar.me está processando este pedido a serviço de InstaSorteio, ao continuar, você concorda com os ',
    purchaseTerms: ' Termos de compra.',
    followTheSteps: 'Siga os passos para realizar',
    informInstaAccount: 'Informe seu @ do usuário do Instagram.',
    selectThePost: 'Selecione seu post e o configure.',
    logginAndGivePermision:
      /* 'Faça login com Facebook e permita a leitura dos comentários do seu perfil do Instagram.', */
      'Insira seu melhor e-mail',
    howDoesTheFreeDrawWork: 'Como funciona o sorteio gratuito?',
    howDoesTheFreeDrawWorkAnswerPt1:
      'O sorteio grátis funciona para os sorteios que tenham ',
    howDoesTheFreeDrawWorkAnswerPt2:
      ' e tem como obrigatoriedade a conexão entre Facebook e Instagram. Pois, são simples de captar e carregar os comentários. Caso o seu sorteio tenha mais que esse número, é preciso usar o Sorteio em Nuvem.',
    howDoesTheClouldDrawWork: 'Como funciona o Sorteio em Nuvem?',
    moreThan5k: 'mais de 300 comentários',
    lessThan5k: 'menos de 300 comentários',
    howDoesTheClouldDrawWorkAnswerPt1:
      'O carregamento em nuvem é utilizado para sorteios que tenham  ',
    howDoesTheClouldDrawWorkAnswerPt2:
      ' e tem um custo simbólico. Pois, esses tipos de sorteios têm uma complexidade maior e precisam de mais cuidados para captar e carregar todos os comentários.  Além disso, nossa ferramenta carrega os comentários na nuvem, então não há risco de perder comentários e o melhor: ',
    howDoesTheClouldDrawWorkAnswerPt3:
      'Utilizando o carregamento em nuvem você não precisa ter a conexão entre Facebook e Instagram.',
    extra: 'Extra: ',
    howDoesTheClouldDrawWorkAnswerPt4:
      'Você pode ter menos de 300 comentários e utilizar o Sorteio em Nuvem para facilitar todo o processo de sorteio.',

    whyDoINeedToConnectMyFacebook:
      'Por que eu preciso conectar o Facebook ao Instagram?',
    whyDoINeedToConnectMyFacebookAnswer:
      'Esse processo é necessário para nossa ferramenta se conectar com o Facebook, que também é dono do Instagram, conseguir identificar todos os comentários. Toda conexão é feita dentro do próprio Facebook e segue os padrões de privacidade da rede.',
    howDoIConnectMyFacebook: 'Como eu conecto o Facebook ao Instagram?',
    howDoIConnectMyFacebookAnswerPt1:
      '1 - Acesse o Facebook páginas e crie uma página (ou acesse sua página já criada); ',
    howDoIConnectMyFacebookAnswerPt2:
      '2 - Clique em Configuração de Página e na sequência em Instagram;',

    howDoIConnectMyFacebookAnswerPt3:
      '3 - Agora é só preencher com login e senha do seu Instagram… prontinho. Entre no instasorteio.com.br e faça seu sorteio!',

    areThePaymentMethodsSafe: 'Os métodos de pagamento são seguros?',
    areThePaymentMethodsSafeAnswer:
      'Os usuários que optarem pelo carregamento em nuvem podem realizar a transação com tranquilidade, pois os métodos de pagamento: pix e cartão de crédito são extremamente seguros.',
    whyUseThePC: 'Por que fazer sorteio pelo computador?',
    whyUseThePCAnswer:
      'Realizar o sorteio pelo computador facilita todo o processo de conexão com o Facebook e pode deixar o sorteio ainda mais ágil e prático.',
    stuckDraw: 'Meu carregamento em nuvem está travado, o que eu faço?',
    postNotFound: 'Meu post não foi localizado, o que eu faço?',
    postNotFoundAnswer:
      'Clique na imagem do sorteio e copie o link do post (no feed do instagram). Agora, coloque na barra da tela selecionar o post no site.',
    stuckDrawAnswer:
      'Envie uma mensagem para o nosso suporte na DM do Instagram e informe o @ do Instagram e o link que utilizou para sortear, assim nossa equipe entrará em contato para te auxiliar.',

    loadingLinkCalloutTitle: 'Enviamos o link no seu email',
    loadingLinkCalloutText:
      'Por segurança, também enviamos o link do carregamento direto no seu email.',
    downloadNotallowed: 'Não é possivel baixar os comentários do link!',
    paidForTheDrawQuestion:
      'Paguei meu sorteio hoje, posso sortear até qual dia?',
    paidForTheDrawAnswer:
      'Você tem 7 dias corridos para realizar o seu sorteio. Esse prazo é contado a partir do momento que você realizou o carregamento dos comentários em nosso site.',
    dueDateQuestion: 'O Carregamento em Nuvem é válido para quantos sorteios?',
    dueDateAnswer:
      'O Carregamento em Nuvem é válido para realizar 1 (um) sorteio. Dentro do prazo sinalizado na resposta anterior.',
    commentsCountQuestion:
      'Por que o número de comentários do meu sorteio não está aparecendo corretamente?',
    commentsCountAnswer:
      'Alguns comentários podem ser visualizados como spam pelo próprio Instagram.',
    clouldUploadWhileCommentingQuestion:
      'Posso fazer o carregamento em nuvem enquanto as pessoas ainda estão participando do sorteio?',
    clouldUploadWhileCommentingAnswer:
      'Não é o mais indicado, pois as pessoas podem comentar no seu sorteio após o carregamento de comentários e, assim, ficarem fora. Por isso, diga em seu post oficial até quando essas pessoas podem participar e faça o carregamento após a finalização dos comentários. :)',
    refundQuestion: 'Como pedir o reembolso?',
    refundAnswer:
      'Entre em contato com o nosso atendimento via contato@instasorteio.com Normalmente, responderemos em até 48h para avaliar seu caso e finalizar o estorno nos próximos dias.',
    connectionProblemPt1: 'Não foi possível estabelecer a conexão!',
    connectionProblemPt2:
      'Tente novamente e selecione corretamente seu perfil. ',
  },
  titles: {
    currency: 'Moeda',
    titleDigitalInfluencer: 'Digital Influencer',
    subtitleDigitalInfluencer:
      'Seja nosso parceiro! Realize sorteio de forma gratuita pelo Instasorteio.',
    randomNumbers: 'Sorteio de números',
    randomNames: 'Sorteio de nomes',
    instaGiveaway: 'Sorteio no Instagram',
    fbGiveaway: 'Sorteio no Facebook',
    rowDices: 'Rolar Dados',
    TwitterGiveaway: 'Sorteio no Twitter',
    TikTokGiveaway: 'Sorteio no TikTok',
    YoutubeGiveaway: 'Sorteio no Youtube',
    randomWheel: 'Roleta Aleatória',
    pageNotFound: 'Ops, esta pagina não existe',
    titleAccountsConnected: 'Selecione uma conta:',
    onlyToday: 'Somente hoje',
    titleLoadingLink: 'Você não precisa mais ficar aqui esperando! 😄',
    subtitleLoadingLink1:
      'Com o sorteio em núvem você copia o link no botão abaixo, e depois, basta colar no navegador que',
    subtitleLoadingLink2: ' o seu carregamento vai voltar de onde parou!',
    titleExceededComments: 'Opa, são muitos comentários!',
    subtitleExceededComments:
      'Seu sorteio excedeu a quantidade máxima de comentários permitidos no carregamento gratuito 😲',
    titleCommentsNotLoaded: 'Não estamos conseguindo carregar os comentários',
    subtitleCommentsNotLoaded:
      'É possível que os comentários da publicação tenham sido desativados ou arquivados. Por isso, não conseguimos carregá-los',
    titleExpiredDraw: 'Sorteio expirado!',
    subtitleExpiredDraw:
      'Seu sorteio parece ter passado do prazo de realização. Geralmente, um sorteio Premium permanecerá ativo por 7 dias após a compra, antes de ser desativado.',
    titleUserNotAllowed: 'Usuário não autorizado!',
    subtitleUserNotAllowed:
      'Ops! Parece que você não é o realizador desse sorteio, por isso você não pode acessar o gerenciamento do sorteio.',
    faq: 'Perguntas Frequentes',
    titleHowItWorks: 'Como funciona o sorteio?',
    subtitleHowItWorks:
      'O sorteio grátis funciona para os sorteios que tenham até 300 comentários.',
    subtitleDrawsInProgress: 'Sorteios pagos em execução',
    titleCheckout: 'Adquira o Premium e turbine todos os seus sorteios!',

    titleSlowLoading: 'Adquira o carregamento em núvem e não espere mais!',
    subtitleSlowLoading:
      'Seu carregamento está demorando muito? Imagine poder fechar seu navegador e voltar só quando tudo estiver pronto!',
    titleShareWinner: 'Baixe a imagem e compartilhe em suas redes sociais! 😄',
    titleNextWithoutLogin:
      'Vai ser difícil, mas vamos tentar fazer isso sem precisar do Facebook!',
    subtitleNextWithoutLogin:
      'Com uma URL direta do seu post, podemos tentar carregar seus comentários diretamente do Instagram.',
    whichIsYourPostURL: 'Insira o link do post oficial',
    searchUser: 'Qual o seu Instagram?',
    searchUserFacebook: 'Conecte-se com o Facebook',
    subTitleSearchUser:
      'Informe pra gente qual é o seu Instagram que vamos encontrar o post do seu sorteio 😉',
    subTitleSearchUserWarning:
      'Faça login de forma segura com o Instagram! Usamos o login social oficial do Instagram, garantindo total segurança para você. Não se preocupe: não temos acesso à sua senha ou dados privados.',
    inputTitleSearchUser: 'Seu Perfil do Instagram',
    whereSelectThisPost: 'Agora, selecione o post do sorteio.',
    titleEmail: 'Qual o seu e-mail?',
    subTitleEmail:
      'A gente precisa do seu e-mail para registrar seu sorteio e te dar todo o suporte que você merece 🤗',
    inputTitleEmail: 'Seu principal e-mail',
    gettingOfficialInstagramData: 'Recebendo dados oficiais do Instagram...',
    weWillMeetYouOnInstagram: 'Te encontramos no Instagram!',
    weWillMeetYouOnFacebook: 'Encontramos sua página no Facebook!',
    isThisYourPost: 'Este é o seu post?',
    howDoYouWantToDraw: 'Como você quer configurar seu sorteio?',
    selectAnotherPost: 'TROCAR POST',
    options: 'Opções',
    numberOfWinnersInEachDraw: 'Número de ganhadores em cada sorteio',
    filterCommentsWithTheHashtag: 'Filtrar comentários com a hashtag:',
    drawTheSamePersonMoreThanOnce: 'Sortear a mesma pessoa mais de uma vez:',
    allowDuplicateComments: 'Permitir comentários duplicados:',
    allowMoreThanOnecommentPerPerson:
      'Permitir mais de 1 comentário por pessoa:',
    weAreLoadingYourComments: '😍 Estamos carregando seus comentários...',
    loadingFinished: 'Carregamento finalizado.',
    weAreReadyCaptain: ' Comentários carregados com sucesso!',
    successfullyLoadedComments: ' Comentários carregados com sucesso!',
    calculating: 'calculando...',
    inMoments: 'em instantes...',
    comments: 'Comentários',
    enjoyTheBenefitsOfCloudLoading:
      'Aproveite os benefícios do carregamento em núvem',
    clouldLoadingAvailable: 'Carregamento em nuvem e recarregar sorteio!',
    clouldLoadingActive: 'Carregamento em nuvem disponível!',
    youCanCloseYourBrowserAndLetUsdoTheRest:
      'Você poderá fechar seu navegador e deixar que a gente faz o resto pra você!',
    iWantToKnow: 'Quero Conhecer',
    shakingTheNotes: 'Que rufem os tambores...  🥁',
    andThePersonDrawnWas: 'E a pessoa sorteada foi: ',
    andThePeopleDrawnWere: 'E as pessoas sorteadas foram: ',
    warning: {
      oopsWeCouldntFindYouOnInstagram1: 'Ops! Não conseguimos te ',
      oopsWeCouldntFindYouOnInstagram2: 'achar no Instagram 🙁',
      oopsWeCouldntFindYourPost:
        'Ops! Não conseguimos encontrar a sua publicação 🙁',
      verifyYourLink: 'Verifique o link e tente novamente.',
      weWereUnableToLoadYourComments1: 'Não conseguimos carregar ',
      weWereUnableToLoadYourComments2: ' seus comentários 🙁',
      clickingYouAccept: 'Ao clicar em conectar você aceita os',
      fbAuthSubtitle:
        'Como o Facebook é dono do Instagram, alguns imprevistos podem acontecer as vezes.',
      connectfb: 'Conecte o Facebook para carregarmos seu perfil através dele!',
      fbAPI:
        'Utilizamos uma API oficial do próprio Facebook para proteger sua conexão',
      relax: 'Fique tranquilo!',
      connectWithoutFb: 'Se você quiser continuar sem conectar com o Facebook',
      problemLoading:
        'Houve um problema no carregamento, por favor, tente mais tarde.',
      invalidLink:
        'Link Invalido, por favor verifique o link e tente novamente.',
      failedPayment: 'Pagamento falhou, tentar novamente?',
    },
    button: {
      downloadFeed: 'Baixar Feed',
      downloadStories: 'Baixar Stories',

      details: 'Mais Opções',
      creditCard: 'cartão de crédito',

      loading: `Carregando`,
      readMore: 'Carregar mais',
      pastePostUrl: 'Colar link do post',
      searchPostGallery: 'Buscar post na galeria',
      thiIsNotYourProfile: 'Esse não é seu perfil',
      clickHere: 'Clique Aqui',
      wantToPastTheLinkToYourPost: 'Quer colar o link do seu post?',
      noChangePost: 'Não, alterar post',
      yesContinue: 'Sim, continuar',
      loadComments: 'Revise seu carregamento',
      drawAComments: 'Sortear comentário!',
      loginWithFacebook: 'Login com Facebook',
      connectToFacebook: 'Conecte-se ao Facebook',

      newDraw: 'Sortear Novamente',
      newDraw2: 'Novo sorteio',
      sharedResult: 'Compartilhar resultado',
      loadPost: 'Carregar posts',
      searchingPost: 'Estamos buscando sua publicação',
      downloadImage: 'Baixar Imagem',
      successfulDownload: 'Download realizado com sucesso!',
      aquireVIP: 'Adquirir Sorteio Premium',
      iWannaCloudLoading: 'Quero o carregamento em núvem!',
      okGotIt: 'Confirmo, já verifiquei a publicação',
      iWannaMakeANewDraw: 'Quero realizar um novo sorteio',
      iWannaMakeMyDraw: 'Quero realizar o meu sorteio',
      chooseAnotherPost: 'Escolher outro post',
      iWantToBePremium: 'Quero ser Premium',
      copy: 'Copiar',
      copied: 'Copiado!',
      access: 'Acessar',
      newGiveaway: 'Novo sorteio',
      desconnect: 'Desconectar',
    },
    prizeDraw: 'sorteio',
    heldOn: 'Realizado no dia ',
    success: 'Sucesso!',
    placeHolder: {
      userInstagram: 'Digite seu perfil',
      emailInput: 'Digite seu e-mail',
      completeName: 'Digite seu nome completo',
      cardNumber: 'Digite o número do cartão',
      year: 'ano',
      month: 'mês',
    },
    headers: {
      post: 'Post',
      comments: 'Comentários',
      date: 'Data',
      progress: 'Progresso',
      actions: 'Ações',
    },
    publications: 'Publicações',
    followers: 'Seguidores',
    following: 'Seguindo',
  },
  step: {
    one: 'PASSO 1',
    two: 'PASSO 2',
    three: 'PASSO 3',
    titleOne: 'Encontre seu perfil',
    titleTwo: 'Escolha seu post',
    titleThree: 'Faça o sorteio!',
  },
  changePost: {
    areYouSure: 'Você tem certeza?',
    ifYouHaveNotMadeThePayment:
      'Caso já tenha feito o pagamento aguarde ser direcionado para a tela de carregamento desta publicação, pois ele não poderá ser usado em outra publicação.',
    chooseAnotherPost: 'Escolher outro post',
    cancel: 'Cancelar',
  },
};
