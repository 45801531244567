import React, { useState } from 'react';

export interface StepForm {
  firstName: string;
  lastName: string;
  email: string;
  color: string;
}

interface StepProps {
  step: number;
  labels: string[];
  getDefaultForm: () => StepForm;
  resetSteps: () => void;
  nextStep: () => number;
  previewStep: () => number;
  getMaxStep: () => number;
}

export const StepContext = React.createContext<StepProps>({} as StepProps);

export const StepProvider = (props: any) => {
  const [step, setStep] = useState<number>(0);
  const labels = ['Escolha seu Perfil', 'Escolha seu Post', 'Faça o Sorteio!'];

  function getDefaultForm(): StepForm {
    return {
      firstName: '',
      lastName: '',
      email: '',
      color: '',
    };
  }

  function resetSteps(): void {
    setStep(0);
  }

  function getMaxStep(): number {
    return 2;
  }

  function nextStep(): number {
    setStep(Math.min(step + 1, getMaxStep()));

    return step;
  }

  function previewStep(): number {
    setStep(Math.max(step - 1, 0));

    return step;
  }

  const { children } = props;
  return (
    <StepContext.Provider
      value={{
        step,
        labels,
        getDefaultForm,
        resetSteps,
        nextStep,
        previewStep,
        getMaxStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
