export default {
  selectLanguage: 'Select the language',
  languages: {
    portuguese: 'Português',
    english: 'English',
  },
  errors: {
    pagarme: {
      e1011: 'Invalid Card Number, CVV or Expiration Information.',
      e1004: 'Card blocked by issuing bank. Contact the bank/card issuer.',
      e1000:
        'Unauthorized transaction. Card blocked by issuing bank. Contact the bank/card issuer.',
      e1016: 'Insufficient funds.',
      e1019:
        'The bank &amp; s prevention system did not authorize the purchase. Contact the bank/card issuer.',
      e2000: 'Transaction refused by bank, contact bank/card issuer.',
    },
  },
  menu: {
    works: 'How does it work',
    draw: 'New giveaway',
    contact: 'Contact Us',
    copyright1: 'Copyright 2021',
    copyright2: 'All rights reserved.',
    terms: 'Terms of use.',
    privacy: 'Privacy Policy',
    lang: 'Select the language',
    followTitle: 'Follow us',
    follow: 'Follow',
    next: 'Continue',
    home: 'Home',
    ourApps: 'Our Giveaways',
  },
  labels: {
    postURL: 'Post link',
  },
  texts: {
    pass: 'Password',
    invalidPass: 'Invalid password',
    invalidEmail: 'Invalid email',
    hint: 'Hint!',
    listItemDigInfluencer1: 'Have at least 300.000 followers.',
    listItemDigInfluencer2:
      'Send your email to our Instagram @instasorteioo through your profile.',
    listItemDigInfluencer3:
      'Wait for your giveaway to be authorized (24 working hours).',
    posts: 'Posts',
    and: ' and ',
    clouldLoadingBenefits:
      'Click on details and enjoy the benefits we have reserved for you!',
    previousPrice: 'R$ 34,90',
    discountPercent: '15%',
    total: 'Total:',
    version: 'version',
    discount: 'Discount:',
    value: 'Value:',
    textShareWinner:
      'Post on Istagram and tag the @instasorteioo page and we will post your result on our official profile!',
    textSlowLoading: 'With Premium Giveaway you get this and much more! ',

    exceededCommentsItem1: 'No limit on the giveaway comments',
    exceededCommentsItem2: 'Execution of the giveaway entirely in the cloud',
    exceededCommentsItem3: 'Instant access to loading giveaway',
    exceededCommentsItem4: 'Privileged connection with Instagram',

    slowLoadingListItem1: 'No limit on the giveaway post comments;',
    slowLoadingListItem2: 'Execution of the draw entirely in the cloud;',
    slowLoadingListItem3: 'Instant access to loading giveways;',
    slowLoadingListItem4: 'Privileged connection with Instagram;',

    reviewWhatYouAreAquiring: 'Review what you are getting',
    clouldUpload: 'Cloud upload',
    commentsLoaded: ' loaded comments',
    lumpSumPayment: 'Full payments without monthly fees',
    payAnytimeWithPix: 'Pay with Pix anytime.',
    instantFastAndSafe: 'It is all instant, fast and secure',
    // //////////////////
    howPixWorks1: 'Open your bank app and select PIX as the payment method',
    howPixWorks2: 'Point your phone camera to screen and scan the QR Code.',
    howPixWorks3: 'Confirm the payment information and complete you purchase.',
    copyPixCode: 'Copy PIX code',
    camera: 'Point your camera here',
    waitingForPayment:
      "We are waiting for your payment. But, don't worry, sometimes it can take up to 3 minutes for the system to recognize your payment",
    youAreAt: 'You are at',
    aSafeEnvironment: 'a safe environment',
    pagarmeIsProcessing:
      'Pagar.me is processing this order at the service of InstaSorteio, by continuing, you agree to the Terms of Use.',
    followTheSteps: 'Follow those steps to proceed',
    informInstaAccount: 'Inform your Instagram @username.',
    selectThePost: 'Select your post and configure it.',
    logginAndGivePermision:
      /* 'Login with Facebook and allow the reading of the comments of your Instagram profile.', */
      'Type your main e-mail',
    howDoesTheFreeDrawWork: 'How does the free giveaway works?',
    howDoesTheFreeDrawWorkAnswerPt1:
      'The free giveaway works for posts that have ',
    howDoesTheFreeDrawWorkAnswerPt2:
      ' less than 5,000 comments and the connection between Facebook and Instagram is mandatory. They are simple to capture and upload comments. If your giveaway have more than this number, you must use the Cloud Upload.',
    moreThan5k: 'more than 5k',
    lessThan5k: 'less than 5k',
    howDoesTheClouldDrawWork: 'How does the Cloud Giveaway work?',
    howDoesTheClouldDrawWorkAnswerPt1:
      'Cloud upload is used for giveaway that have ',
    howDoesTheClouldDrawWorkAnswerPt2:
      " and it has a symbolic cost. Because, these types of giveways have a greater complexity and need more care to capture and load all comments. Plus, our tool uploads comments to the cloud, so there's no risk of losing comments and the best part: ",
    howDoesTheClouldDrawWorkAnswerPt3:
      "Using cloud upload you don't need to have a connection between Facebook and Instagram.",
    extra: 'Extra: ',
    whyDoINeedToConnectMyFacebook:
      'Why do I need to connect Facebook to Instagram?',
    whyDoINeedToConnectMyFacebookAnswer:
      "This process is necessary for our tool to connect with Facebook, which also owns Instagram, to be able to identify all comments. Every connection is made within Facebook itself and follows the network's privacy standards.",
    howDoIConnectMyFacebook: 'How do I connect Facebook to Instagram?',
    howDoIConnectMyFacebookAnswerPt1:
      '1 - Access Facebook pages and create a page (or access your already created page); ',
    howDoIConnectMyFacebookAnswerPt2:
      '2 - Click on Page Setup and then Instagram; ',

    howDoIConnectMyFacebookAnswerPt3:
      "3 - Now just fill in your Instagram login and password... that's it. Go to instasorteio.com.br and make your giveaway contest!",
    areThePaymentMethodsSafe: 'Are the payment methods safe?',
    areThePaymentMethodsSafeAnswer:
      'Users who opt for cloud upload can complete the transaction with peace of mind, as payment methods: pix and credit card are extremely secure.',
    whyUseThePC: 'Why make the giveaway contest by the PC?',
    whyUseThePCAnswer:
      'Making the giveaway contest by the PC make the entire process easier to connect to Facebook and can make the giveaway faster and more practical.',
    stuckDraw: 'My cloud upload is stuck, what should I do?',
    stuckDrawAnswer:
      '1st - Check if your comments are disabled, if so enable them and wait a few minutes to return to normal loading. If the problem persists, please send a message to our support in the Instagram DM and inform the @ Instagram and the link you used to give away, so our team will contact you to help you.',
    postNotFound: 'My post was not found, what do I do?',
    postNotFoundAnswer:
      'Click on the giveaway image and copy the post link (in the instagram feed). Now, paste on the bar in the screen select the post on the website.',
    loadingLinkCalloutTitle: 'We send the link in your email',
    loadingLinkCalloutText:
      'For security reasons, we also send the upload link directly to your email.',
    downloadNotallowed:
      'It is not possible to download the comments from the link!',
    paidForTheDrawQuestion:
      'I paid for my giveaway today, can I make the contest until which day?',
    paidForTheDrawAnswer:
      'You have 7 calendar days to make your giveaway contest. This period starts from the moment you upload the comments on our website.',
    dueDateQuestion: 'How many draws is Cloud Upload valid for?',
    dueDateAnswer:
      'The Cloud Upload is valid to perform 1 (one) giveway. Within the deadline indicated in the previous answer.',
    commentsCountQuestion: "Why isn't my comments count showing correctly?",
    commentsCountAnswer:
      'Some comments may be viewed as spam by Instagram itself.',
    clouldUploadWhileCommentingQuestion:
      'Can I do Cloud Upload while people are still participating in the giveaway?',
    clouldUploadWhileCommentingAnswer:
      "It's not the most suitable, as people can comment on your post after uploading comments and thus stay out. So, say in your official post how long these people can participate and upload after finalizing the comments. :)",
    refundQuestion: 'How to request a refund?',
    refundAnswer:
      'Get in touch with our customer service via contato@instasorteio.com We will normally answer within 48 hours to assess your case and finalize the chargeback in the coming days.',

    connectionProblemPt1: 'Unable to establish connection!',
    connectionProblemPt2: 'Please try again and correctly select your profile.',
  },
  titles: {
    currency: 'Currency',
    titleDigitalInfluencer: 'Digital Influencer',
    subtitleDigitalInfluencer:
      'Be our partner! Make giveaways for free by Instasorteio.',
    randomNumbers: 'Random Numbers',
    randomNames: 'Random Names',
    instaGiveaway: 'Instagram Giveaway',
    fbGiveaway: 'Facebook Giveaway',
    rowDices: 'Row Die',
    randomWheel: 'Random Wheel',
    TwitterGiveaway: 'Twitter Giveaway',
    TikTokGiveaway: 'TikTok Giveaway',
    YoutubeGiveaway: 'Youtube Giveaway',
    pageNotFound: 'Ops, this page does not exist',
    titleAccountsConnected: 'Select an account:',
    onlyToday: 'Only today',
    titleLoadingLink: 'You dont have to wait here anymore! 😄',
    subtitleLoadingLink1:
      'With the cloud upload you copy the link on the button below, and then just paste in the browser that',
    subtitleLoadingLink2: ' your upload will pick up where it left off!',
    titleExceededComments: 'Oops, that is a lot of comments!',
    subtitleExceededComments:
      'Your draw has exceeded the maximum amount of comments allowed in the free upload carregamento 😲',
    titleCommentsNotLoaded: 'We were unable to load the comments.',
    subtitleCommentsNotLoaded:
      'Post comments may have been disabled or archived. That is why we cannot load them',
    titleExpiredDraw: 'Draw expired!',
    subtitleExpiredDraw:
      'Your draw seems to have passed the deadline. Generally, a Premium draw will remain active for 7 days after purchase, before being deactivated.',
    titleUserNotAllowed: 'Unauthorized User!',
    subtitleUserNotAllowed:
      'Oops! It appears that you are not the organizer of this draw, so you cannot access the draw management.',
    faq: 'FAQ:',
    titleHowItWorks: 'How does the giveaway work?',
    subtitleHowItWorks:
      'The free giveaways works for giveaways that have up to 5,000 comments.',
    subtitleDrawsInProgress: 'Paid giveaways running',
    titleCheckout: 'Get Premium and boost all your giveaways!',

    titleSlowLoading: 'Purchase cloud loading and don not wait any longer!',
    subtitleSlowLoading:
      'Is loading your comments taking too long? Imagine being able to close your browser and come back only when everything is ready!',
    titleShareWinner:
      'Download the image and share it on your social networks! 😄',
    titleNextWithoutLogin:
      "It is going to be hard, but let's try to do it without Facebook!",
    subtitleNextWithoutLogin:
      'With a direct URL from your post, we can try to upload your comments directly from Instagram.',
    whichIsYourPostURL: 'Insert the official link of your post',
    searchUser: 'What is your Instagram account?',
    searchUserFacebook: 'Connect with Facebook',
    subTitleSearchUser:
      'Let us know what is your Instagram is and we will find your post to make the giveaway 😉',
    subTitleSearchUserWarning:
      "Log in securely with Instagram! We use Instagram's official social login, ensuring complete security for you. Don't worry: we don't have access to your password or private data.",

    inputTitleSearchUser: 'Your Instagram Profile',
    whereSelectThisPost: 'Now select the giveaway post.',
    titleEmail: 'What is your email?',
    subTitleEmail:
      'We need your email to register your draw and give you all the support you deserve 🤗',
    inputTitleEmail: 'Your main email',
    gettingOfficialInstagramData: 'Receiving official data from Instagram...',
    weWillMeetYouOnInstagram: 'We found you on Instagram!',
    weWillMeetYouOnFacebook: 'We found your Facebook page!',
    isThisYourPost: 'Is this your post?',
    howDoYouWantToDraw: 'How do you want to set up your giveaway?',
    selectAnotherPost: 'Select another post',
    options: 'Options',
    numberOfWinnersInEachDraw: 'Number of winners in each giveaway',
    filterCommentsWithTheHashtag: 'Filter comments with the hashtag:',
    drawTheSamePersonMoreThanOnce:
      'Allow the same person to win more than once:',
    allowDuplicateComments: 'Allow duplicate comments:',
    allowMoreThanOnecommentPerPerson: 'Allow more than 1 comment per person:',
    weAreLoadingYourComments: 'We are loading your comments...',
    loadingFinished: 'Loading completed.',
    weAreReadyCaptain: ' Comments uploaded successfully!',
    successfullyLoadedComments: ' Comments uploaded successfully!',
    calculating: 'calculating...',
    inMoments: 'in a moment...',
    comments: 'Comments',
    enjoyTheBenefitsOfCloudLoading: 'Enjoy the benefits of cloud loading',
    clouldLoadingAvailable: 'Cloud upload available!',
    clouldLoadingActive: 'Cloud upload enabled',
    youCanCloseYourBrowserAndLetUsdoTheRest:
      'You can close your browser and let us do the rest for you!',
    iWantToKnow: 'Find out more',
    shakingTheNotes: 'Let the drums roll...  🥁',
    andThePersonDrawnWas: 'And the winner is: ',
    andThePeopleDrawnWere: 'And the winners are: ',
    warning: {
      oopsWeCouldntFindYouOnInstagram1: 'Ops! We could not find you on',
      oopsWeCouldntFindYouOnInstagram2: 'Instagram🙁',
      oopsWeCouldntFindYourPost: 'Ops! We could not find your post 🙁',
      verifyYourLink: 'Please check the link and try again.',
      weWereUnableToLoadYourComments1: 'We could not load ',
      weWereUnableToLoadYourComments2: ' your comments 🙁',
      clickingYouAccept: 'Clicking you accept the',
      fbAuthSubtitle:
        'As Facebook owns Instagram, some unforeseen events can sometimes happen.',
      connectfb:
        'Connect to Facebook so we can upload your profile through it!',
      fbAPI: 'We use an official Facebook API to protect your connection',
      relax: 'Relax!',
      connectWithoutFb:
        'If you want to continue without connecting with Facebook',
      problemLoading: 'There was a problem loading, please try again later.',
      invalidLink: 'Invalid link, please check the link and try again.',
      failedPayment: 'Payment failed, try again?',
    },
    button: {
      downloadFeed: 'Download Feed',
      downloadStories: 'Download Stories',
      details: 'Details',
      creditCard: 'Credit Card',

      loading: `Loading`,
      readMore: 'Load More',
      pastePostUrl: 'Copy post link',
      searchPostGallery: 'Search post in the feed',
      thiIsNotYourProfile: 'This is not your profile?',
      clickHere: 'Click here',
      wantToPastTheLinkToYourPost:
        'Do you want to paste the link of your post?',
      noChangePost: 'No, choose another post',
      yesContinue: 'Yes, proceed',
      loadComments: 'Load comments',
      drawAComments: 'Draw a comment!',
      loginWithFacebook: 'Login with Facebook',
      connectToFacebook: 'Connect to Facebook',

      newDraw: 'New giveaway',
      newDraw2: 'New giveaway',
      sharedResult: 'Share result',
      loadPost: 'Load posts',
      searchingPost: 'We are searching for your post',
      downloadImage: 'Download Image',
      successfulDownload: 'Download made successfully!',
      aquireVIP: 'Aquire the Premium Giveaway',
      iWannaCloudLoading: 'I want the cloud loading',
      okGotIt: 'Ok, I got it',
      iWannaMakeANewDraw: 'I want to make a new giveaway',
      iWannaMakeMyDraw: 'I want to hold my draw',
      chooseAnotherPost: 'Choose another post',
      iWantToBePremium: 'I want to be Premium',
      copy: 'Copy',
      copied: 'Copied!',
      access: 'Access',
      newGiveaway: 'New Giveaway',
      desconnect: 'disconnect',
    },
    prizeDraw: 'giveaway',
    heldOn: 'Held on ',
    success: 'Success!',
    placeHolder: {
      userInstagram: 'Insert your username',
      emailInput: 'Insert your e-mail',
      completeName: 'Insert your complete name',
      cardNumber: 'Insert your card number',
      year: 'year',
      month: 'month',
    },
    headers: {
      post: 'Post',
      comments: 'Comments',
      date: 'Date',
      progress: 'Progress',
      actions: 'Actions',
    },
    publications: 'Posts',
    followers: 'Followers',
    following: 'Following',
  },
  step: {
    one: 'STEP 1',
    two: 'STEP 2',
    three: 'STEP 3',
    titleOne: 'Find your profile',
    titleTwo: 'Choose your post',
    titleThree: 'Hold the draw!',
  },
  changePost: {
    areYouSure: 'Are you sure?',
    ifYouHaveNotMadeThePayment:
      'If you have already made the payment, wait to be directed to the loading screen of this publication, as it cannot be used in another publication.',
    chooseAnotherPost: 'Choose another post',
    cancel: 'Cancel',
  },
};
