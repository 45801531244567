export default {
  selectLanguage: 'Elige el idioma',
  languages: {
    portuguese: 'Português',
    english: 'Inglês',
  },
  errors: {
    pagarme: {
      e1011: 'Número de tarjeta, CVV o información de vencimiento no válidos.',
      e1004:
        'Tarjeta bloqueada por el banco emisor. Comuníquese con el banco / emisor de la tarjeta.        ',
      e1000:
        'Transacción no autorizada. Remitido (sospecha de fraude) por el banco emisor. Comuníquese con el banco / emisor de la tarjeta.        ',
      e1016: 'Fondos insuficientes.      ',
      e1019:
        'El sistema de prevención del banco no autorizó la compra. Comuníquese con el banco / emisor de la tarjeta.        ',
      e2000:
        'Transacción rechazada por el banco, comuníquese con el banco / emisor de la tarjeta.        ',
    },
  },
  menu: {
    works: '¿Como funciona?',
    draw: 'Realizar sorteo  ',
    contact: 'Hable con nosotros  ',
    copyright1: 'Copyright 2021.',
    copyright2: 'Todos los derechos reservados.',
    terms: 'Terminos de uso.',
    privacy: 'Política de privacidad',
    lang: 'Seleccione el idioma',
    followTitle: '¡Síguenos!',
    follow: 'Seguir',
    next: 'Continuar',
    home: 'Comienzo',
    ourApps: 'Nuestros sorteos',
  },
  labels: {
    postURL: 'Enlace de publicación',
  },
  texts: {
    pass: 'Contraseña',
    invalidPass: 'Contraseña inválida',
    invalidEmail: 'Correo electrónico inválido',
    hint: 'Consejo!',
    listItemDigInfluencer1: 'Tener al menos 300.000 seguidores.',
    listItemDigInfluencer2:
      'Envía tu correo electrónico y tu URL de regalo a nuestro Instagram @instasorteioo a través de tu perfil.',
    listItemDigInfluencer3:
      'Espera a que se autorice tu sorteo (24 horas laborables).',
    posts: 'Publicaciones',
    and: ' e ',
    clouldLoadingBenefits:
      '¡Haz clic en los detalles y disfruta de los beneficios que tenemos reservados para ti!',
    previousPrice: 'R$ 34,90',
    discountPercent: '15%',
    total: 'Total:',
    discount: 'Descuento:',
    value: 'Valor:',
    version: 'versión',
    textShareWinner:
      '¡Publique en Instagram y marque la página @instasorteioo y publicaremos su resultado en nuestro perfil oficial!      ',
    textSlowLoading: '¡Con Premium Raffle obtienes esto y mucho más! ',

    exceededCommentsItem1: 'No hay límite en los comentarios de la rifa.',
    exceededCommentsItem2: 'Ejecución del sorteo íntegramente en la nube ',
    exceededCommentsItem3: 'Acceso instantáneo a la carga de sorteos',
    exceededCommentsItem4: 'Conexión privilegiada con Instagram',

    slowLoadingListItem1: 'Ejecución del sorteo íntegramente en la nube;',
    slowLoadingListItem2: 'Acceso instantáneo a la carga de la lotería;',
    slowLoadingListItem3: 'No hay límite en los comentarios de la rifa.',
    slowLoadingListItem4: 'Conexión privilegiada con Instagram.',

    reviewWhatYouAreAquiring: 'Revisa lo que obtienes',
    clouldUpload: 'Carga en la nube',
    commentsLoaded: ' Comentarios subidos',
    lumpSumPayment: 'Pago unico sin cuotas mensuales',
    payAnytimeWithPix: 'Pague con Pix en cualquier momento.',
    instantFastAndSafe: 'Todo es instantáneo, rápido y seguro',
    howPixWorks1:
      'Abra la aplicación de su institución financiera e ingrese al entorno PIX.',
    howPixWorks2:
      'Apunte la cámara de su teléfono celular y escanee el código QR a un lado.',
    howPixWorks3: 'Confirma tu información de pago y completa tu compra.',
    copyPixCode: 'Copiar código de Pix',
    camera: 'Apunte su cámara aquí',
    waitingForPayment:
      'Estamos esperando su pago, pero no se preocupe, a veces puede tomar hasta 30 minutos confirmar la transferencia.',
    youAreAt: 'Estas en un ambiente',
    aSafeEnvironment: 'ambiente seguro',
    pagarmeIsProcessing:
      'Pagar.me está procesando este pedido al servicio de InstaSorteio, al continuar, está de acuerdo con el ',
    purchaseTerms: ' Condiciones de compra.',
    followTheSteps: 'Siga los pasos para realizar',
    informInstaAccount: 'Ingrese su @ de usuario de Instagram.',
    selectThePost: 'Selecciona tu publicación y configúrala.',
    logginAndGivePermision:
      /* ' Inicie sesión con Facebook y permita leer comentarios de su perfil de Instagram.', */
      'Introduce tu mejor correo electrónico',
    howDoesTheFreeDrawWork: '¿Cómo funciona el sorteo gratuito?',
    howDoesTheFreeDrawWorkAnswerPt1:
      'El dibujo gratuito funciona para dibujos que tienen',
    howDoesTheFreeDrawWorkAnswerPt2:
      ' y la conexión entre Facebook e Instagram es obligatoria. Bueno, son simples de capturar y cargar comentarios. Si su sorteo tiene más de este número, debe usar Dibujo de nube..',
    howDoesTheClouldDrawWork: '¿Cómo funciona el sorteo en la nube?',
    moreThan5k: 'más de 5,000 comentarios',
    lessThan5k: 'menos de 5,000 comentarios',
    howDoesTheClouldDrawWorkAnswerPt1:
      'La carga en la nube se utiliza para sorteos que tienen  ',
    howDoesTheClouldDrawWorkAnswerPt2:
      'y tiene un costo simbólico. Porque, este tipo de dibujos tienen una mayor complejidad y necesitan más cuidado para capturar y cargar todos los comentarios. Además, nuestra herramienta carga comentarios en la nube, por lo que no hay riesgo de perder comentarios y la mejor parte: ',
    howDoesTheClouldDrawWorkAnswerPt3:
      'Al usar la carga en la nube, no es necesario tener una conexión entre Facebook e Instagram.',
    extra: 'Extra: ',
    howDoesTheClouldDrawWorkAnswerPt4:
      'Puede tener menos de 5.000 comentarios y utilizar Cloud Draw para facilitar todo el proceso de dibujo.',

    whyDoINeedToConnectMyFacebook:
      '¿Por qué necesito conectar Facebook a Instagram?',
    whyDoINeedToConnectMyFacebookAnswer:
      'Este proceso es necesario para que nuestra herramienta se conecte con Facebook, que también es propietaria de Instagram, para poder identificar todos los comentarios. Cada conexión se realiza dentro de Facebook y sigue los estándares de privacidad de la red.',
    howDoIConnectMyFacebook: '¿Cómo conecto Facebook a Instagram?',
    howDoIConnectMyFacebookAnswerPt1:
      '1 - Acceda a las páginas de Facebook y cree una página (o acceda a su página ya creada); ',
    howDoIConnectMyFacebookAnswerPt2:
      '2 - Haga clic en Configuración de página y luego en Instagram;',

    howDoIConnectMyFacebookAnswerPt3:
      '3 - Ahora solo ingrese su nombre de usuario y contraseña de Instagram ... eso es todo. ¡Entra en instasorteio.com.br y haz tu sorteo!',

    areThePaymentMethodsSafe: '¿Son seguros los métodos de pago?',
    areThePaymentMethodsSafeAnswer:
      'Los usuarios que opten por la carga en la nube pueden completar la transacción con tranquilidad, ya que los métodos de pago: la foto y la tarjeta de crédito son extremadamente seguros.',
    whyUseThePC: '¿Por qué dibujar por computadora?',
    whyUseThePCAnswer:
      'Realizar el sorteo por computadora facilita todo el proceso de conexión a Facebook y puede hacer que el sorteo sea aún más ágil y práctico.',
    stuckDraw: 'Mi carga en la nube está bloqueada, ¿qué hago?',
    postNotFound: 'No se encontró mi publicación, ¿qué hago?',
    postNotFoundAnswer:
      'Haga clic en la imagen del sorteo y copie el enlace de la publicación (en el feed de instagram). Ahora, coloque en la barra de pantalla y seleccione la publicación en el sitio web.',
    stuckDrawAnswer:
      'Envíe un mensaje a nuestro soporte en Instagram DM e informe a @ Instagram y el enlace que usó para regalar, para que nuestro equipo se comunique con usted para ayudarlo.',

    loadingLinkCalloutTitle: 'Le enviamos el enlace en su correo electrónico',
    loadingLinkCalloutText:
      'Por seguridad, también enviamos el enlace de carga directamente a su correo electrónico.',
    downloadNotallowed:
      '¡No es posible descargar los comentarios desde el enlace!',
    paidForTheDrawQuestion:
      'Pagué mi sorteo hoy, ¿puedo sacar hasta qué día?   ',
    paidForTheDrawAnswer:
      'Dispones de 7 días naturales para realizar tu sorteo. Este período comienza desde el momento en que carga los comentarios en nuestro sitio web.',
    dueDateQuestion: ' ¿Para cuántos dibujos es válida la carga en la nube?',
    dueDateAnswer:
      'La carga en la nube es válida para realizar 1 (un) sorteo. Dentro del plazo indicado en la respuesta anterior.',
    commentsCountQuestion:
      '¿Por qué mi número de comentario de la rifa no se muestra correctamente?',
    commentsCountAnswer:
      'Algunos comentarios pueden ser vistos como spam por el propio Instagram.',
    clouldUploadWhileCommentingQuestion:
      '¿Puedo cargar en la nube mientras la gente todavía está participando en el sorteo?',
    clouldUploadWhileCommentingAnswer:
      'No es el más adecuado, ya que la gente puede comentar sobre tu rifa después de subir comentarios y así quedarse fuera. Entonces, diga en su publicación oficial cuánto tiempo pueden participar estas personas y subir después de finalizar los comentarios. )',
    refundQuestion: ' ¿Cómo solicitar un reembolso?',
    refundAnswer:
      'Póngase en contacto con nuestro servicio a través de contato@instasorteio.com Normalmente, responderemos dentro de las 48 horas para evaluar su caso y finalizar el contracargo en los próximos días.     ',
    connectionProblemPt1: '¡No se pudo establecer la conexión!',
    connectionProblemPt2:
      '  Vuelva a intentarlo y seleccione correctamente su perfil.',
  },
  titles: {
    currency: 'moneda',
    titleDigitalInfluencer: 'Influencer Digital ',
    subtitleDigitalInfluencer:
      '¡Sea nuestro socio! Haz un sorteo gratis por Instasorteio.',
    randomNumbers: 'Sorteo de números',
    randomNames: 'Sorteo de nombres',
    instaGiveaway: 'Sorteo no Instagram',
    fbGiveaway: 'Sorteo no Facebook',
    rowDices: 'Tirar el dado',
    TwitterGiveaway: 'Sorteo no Twitter',
    TikTokGiveaway: 'Sorteo no TikTok',
    YoutubeGiveaway: 'Sorteo no Youtube',
    randomWheel: ' Ruleta aleatoria',
    pageNotFound: 'Ops, esta página no existe',
    titleAccountsConnected: 'Seleccionar una cuenta:',
    onlyToday: 'Sólo hoy',
    titleLoadingLink: ' ¡Ya no tienes que esperar aquí! 😄',
    subtitleLoadingLink1:
      ' Con el sorteo en la nube, copie el enlace en el botón de abajo y luego pegue en el navegador que',
    subtitleLoadingLink2: '  ¡Tu carga continuará donde la dejaste!',
    titleExceededComments: '¡Vaya, son muchos comentarios!',
    subtitleExceededComments:
      'Tu rifa superó la cantidad máxima de comentarios permitidos en la carga gratuita. 😲',
    titleCommentsNotLoaded: 'No podemos cargar los comentarios.',
    subtitleCommentsNotLoaded:
      'Los comentarios de las publicaciones pueden haber sido inhabilitados o archivados. Por eso no podemos cargarlos.',
    titleExpiredDraw: '¡Sorteo caducado!',
    subtitleExpiredDraw:
      'Tu sorteo parece haber pasado la fecha límite. Generalmente, un sorteo Premium permanecerá activo durante 7 días después de la compra, antes de ser desactivado.',
    titleUserNotAllowed: '¡Usuario no autorizado!',
    subtitleUserNotAllowed:
      '¡UPS! Parece que no eres el organizador de este sorteo, por lo que no puedes acceder a la gestión del sorteo.',
    faq: 'Preguntas frecuentes',
    titleHowItWorks: '¿Cómo funciona el sorteo?',
    subtitleHowItWorks:
      'El sorteo gratuito funciona para sorteos que tienen hasta 5.000 comentarios.',
    subtitleDrawsInProgress: 'Sorteo pagado en ejecución',
    titleCheckout: '¡Obtén Premium y aumenta todos tus sorteos!',

    titleSlowLoading: ' ¡Compre la carga en la nube y no espere más!',
    subtitleSlowLoading:
      ' ¿Su envío está tardando demasiado? ¡Imagínese poder cerrar su navegador y regresar solo cuando todo esté listo!',
    titleShareWinner:
      '  ¡Descarga la imagen y compártela en tus redes sociales! 😄',
    titleNextWithoutLogin:
      ' Va a ser difícil, ¡pero intentemos hacerlo sin Facebook!',
    subtitleNextWithoutLogin:
      ' Con una URL directa de su publicación, podemos intentar cargar sus comentarios directamente desde Instagram.',
    whichIsYourPostURL: 'Inserta el enlace de la publicación oficial',
    searchUser: '¿Cuál es tu Instagram?',
    searchUserFacebook: 'Conéctate con Facebook',
    subTitleSearchUser:
      'Háganos saber cuál es su Instagram y encontraremos su publicación de sorteo 😉',
    subTitleSearchUserWarning:
      '¡Inicia sesión de forma segura con Instagram! Usamos el inicio de sesión social oficial de Instagram, garantizando total seguridad para ti. No te preocupes: no tenemos acceso a tu contraseña ni a tus datos privados.',
    inputTitleSearchUser: 'Tu perfil de Instagram',
    whereSelectThisPost: 'Ahora seleccione la publicación de dibujo.',
    titleEmail: '¿Cuál es tu email?',
    subTitleEmail:
      'Necesitamos tu correo electrónico para registrar tu sorteo y darte todo el apoyo que te mereces 🤗',
    inputTitleEmail: 'Tu correo electrónico principal',
    gettingOfficialInstagramData: 'Recibiendo datos oficiales de Instagram ...',
    weWillMeetYouOnInstagram: '¡Te encontramos en Instagram!',
    weWillMeetYouOnFacebook: '¡Encontramos tu página de Facebook!',
    isThisYourPost: '¿Es esta tu publicación?',
    howDoYouWantToDraw: '¿Cómo quieres configurar tu sorteo?',
    selectAnotherPost: 'Seleccionar otra publicación',
    options: 'Opciones',
    numberOfWinnersInEachDraw: 'Número de ganadores en cada sorteo',
    filterCommentsWithTheHashtag: ' Filtrar comentarios con el hashtag:',
    drawTheSamePersonMoreThanOnce: 'Dibuja a la misma persona más de una vez:',
    allowDuplicateComments: 'Permitir comentarios duplicados:',
    allowMoreThanOnecommentPerPerson:
      'Permitir más de 1 comentario por persona:',
    weAreLoadingYourComments: '😍 Estamos cargando tus comentarios ...',
    loadingFinished: 'Carga completada.',
    weAreReadyCaptain: ' ¡Comentarios subidos con éxito!',
    successfullyLoadedComments: ' ¡Comentarios subidos con éxito!',
    calculating: 'calculador....',
    inMoments: 'un momento ....',
    comments: 'Comentários',
    enjoyTheBenefitsOfCloudLoading:
      'Disfrute de los beneficios de la carga en la nube',
    clouldLoadingAvailable: '¡Carga en la nube disponible!',
    clouldLoadingActive: '¡Carga en la nube disponible!    ',
    youCanCloseYourBrowserAndLetUsdoTheRest:
      '¡Puede cerrar su navegador y dejar que nosotros hagamos el resto por usted!',
    iWantToKnow: 'Quiero conocer',
    shakingTheNotes: 'Deja que los tambores suenen...  🥁',
    andThePersonDrawnWas: 'Y la persona dibujada fue:',
    andThePeopleDrawnWere: 'Y las personas dibujadas fueron: ',
    warning: {
      oopsWeCouldntFindYouOnInstagram1: '¡UPS! No podemos encontrarte ',
      oopsWeCouldntFindYouOnInstagram2: 'encontrar en Instagram 🙁',

      oopsWeCouldntFindYourPost: '¡UPS! No pudimos encontrar tu publicación 🙁',
      verifyYourLink: 'Comprueba el link e inténtalo de nuevo.',
      weWereUnableToLoadYourComments1: 'No pudimos cargar ',
      weWereUnableToLoadYourComments2: ' tus comentarios 🙁',
      clickingYouAccept: 'Al hacer clic en conectar acepta la',
      fbAuthSubtitle:
        'Como Facebook es dueño de Instagram, a veces pueden ocurrir algunos eventos imprevistos.',
      connectfb:
        '¡Conéctese a Facebook para que podamos cargar su perfil a través de él!',
      fbAPI: 'Usamos una API oficial de Facebook para proteger su conexión.',
      relax: '¡Quédate tranquilo!',
      connectWithoutFb: 'Si desea continuar sin conectarse con Facebook',
      problemLoading:
        'Hubo un problema al cargar. Vuelve a intentarlo más tarde..',
      invalidLink:
        'Enlace no válido, verifique el enlace y vuelva a intentarlo.',
      failedPayment: 'Pago fallido, ¿intentarlo de nuevo?',
    },
    button: {
      downloadFeed: 'Descargar Feed',
      downloadStories: 'Descargar historias',

      details: 'Detalles',
      creditCard: 'tarjeta de crédito',

      loading: `Cargando`,
      readMore: 'carga más',
      pastePostUrl: 'Pegar enlace de publicación',
      searchPostGallery: 'Buscar publicación en la galería',
      thiIsNotYourProfile: 'Este no es tu perfil',
      clickHere: 'Haga clic aquí',
      wantToPastTheLinkToYourPost:
        '¿Quieres pegar el enlace de tu publicación?',
      noChangePost: 'No, cambiar publicación',
      yesContinue: 'si continuar',
      loadComments: 'Cargar comentarios',
      drawAComments: '¡Regala comentario!',
      loginWithFacebook: 'Iniciar sesión con Facebook',
      connectToFacebook: 'Conectarse a Facebook',

      newDraw: 'Sorteo de nuevo',
      newDraw2: 'Nuevo sorteo',
      sharedResult: 'Compartir resultado',
      loadPost: 'Cargar publicaciones',
      searchingPost: ' Buscamos tu publicación',
      downloadImage: 'Descargar imagen',
      successfulDownload: 'Descarga exitosa!',
      aquireVIP: 'Adquirir sorteo premium',
      iWannaCloudLoading: ' Quiero carga en la nube!',
      okGotIt: 'Ok, Entendi',
      iWannaMakeANewDraw: 'Quiero hacer un nuevo sorteo',
      iWannaMakeMyDraw: 'Quiero aguantar mi dibujo',
      chooseAnotherPost: 'Elige otra publicación',
      iWantToBePremium: 'Quiero ser Premium',
      copy: 'Copiar',
      copied: 'Copiado!',
      access: 'Acceso',
      newGiveaway: 'Nuevo sorteo',
      desconnect: 'Desconectar',
    },
    prizeDraw: 'sorteo',
    heldOn: 'Realizado en el día ',
    success: 'Éxito!',
    placeHolder: {
      userInstagram: 'Ingresa tu perfil',
      emailInput: ' Escriba su correo electrónico',
      completeName: ' Ingrese su nombre completo.',
      cardNumber: 'Ingrese el número de tarjeta',
      year: ' año',
      month: 'mes',
    },
    headers: {
      post: 'Post',
      comments: 'Comentarios',
      date: 'Día',
      progress: 'Progreso',
      actions: 'Comportamiento',
    },
    publications: 'Publicaciones',
    followers: 'Seguidores',
    following: 'Siguiente',
  },
  step: {
    one: 'PASO 1',
    two: 'PASO 2',
    three: 'PASO 3',
    titleOne: 'Encuentra tu perfil',
    titleTwo: 'Elige tu publicación',
    titleThree: 'Hacer el sorteo!',
  },
  changePost: {
    areYouSure: '¿Estás seguro?',
    ifYouHaveNotMadeThePayment:
      'Si ya realizó el pago, espere a ser dirigido a la pantalla de carga de esta publicación, ya que no se puede utilizar en otra publicación.',
    chooseAnotherPost: 'Elige otra publicación',
    cancel: 'Cancelar',
  },
};
