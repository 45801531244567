import React, { useState } from 'react';

import storage from './helpers/storage';

interface AuthProps {
  signed: boolean;
  signin: (data: any) => void;
  signout: () => void;
  get: () => any;
}

export const AuthContext = React.createContext<AuthProps>({} as AuthProps);

// eslint-disable-next-line
export const AuthProvider = (props: any) => {
  const storageKey = 'auth';
  const [signed, setSigned] = useState<boolean>(
    !!storage.get(storageKey).token,
  );

  function signin(data: any): void {
    storage.set(storageKey, data, true);
    setSigned(true);
  }

  function signout(): void {
    storage.remove(storageKey);
    setSigned(false);
  }

  function get() {
    return storage.get(storageKey);
  }

  const { children } = props;
  return (
    <AuthContext.Provider value={{ signed, signin, signout, get }}>
      {children}
    </AuthContext.Provider>
  );
};
