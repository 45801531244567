/* eslint-disable camelcase */
import React, { useState } from 'react';

interface UserContextProps {
  userInstagram: UserProps;
  setUserInstagram: any;
}

interface AccountsConnected {
  id: number;
  username: string;
  followers_count: number;
  follows_count: number;
  ig_id: number;
  media: MediaPropsFacebook;
  profile_picture_url: string;
  media_count: number;
}

interface MediaPropsFacebook {
  data: [];
  paging: {
    cursors: {
      after?: string;
      before?: string;
    };
    next?: string;
  };
}

interface PostProps {
  node: any;
  id: any;
  ig_id: any;
  permalink: any;
  media_url: any;
  media_type: any;
  media_url_base64: any;
  shortcode: any;
  comments_count: any;
  isVip: any;
  linkVip: any;
  searchPost: any;
}

interface SetupPostProps {
  double_comments_allowed: boolean;
  draw_per_click: string;
  hashtags: string;
  more_than_one_commentper_person: boolean;
  same_person_more_than_once_allowed: boolean;
}

interface WinnerProps {
  icon: string;
  ig_id: number;
  id_intern: string;
  username: string;
  text: string;
  date: string;
  profile_pic_url?: string;
}

interface PostsVipProps {
  node?: any;
  created_time: number;
  display_url: string;
  display_url_base64: string;
  done: number;
  done_time: number;
  percentLoaded: number;
  shortcode: string;
  total_comments: number;
  url: string;
  username: string;
}

interface UserProps {
  username: string;
  id_facebook: number;
  id: 0;
  ig_id: number;
  isVerified: boolean;
  checkoutDay: string;
  allowConnect: boolean;
  payments: {
    id_card: number;
    id_pix: number;
    cardHash: string;
    status: string;
    method: string;
  };
  accounts_connected: AccountsConnected[];
  followers_count: number;
  follows_count: number;
  profile_pic_url: string;
  profile_pic_url_base64: string;
  email: string;
  loading: {
    initialLoading: boolean;
    porcentloading: number;
    comments_count: number;
  };
  setup: SetupPostProps;
  drawNumber: number;
  winner: WinnerProps[];
  postsVip: PostsVipProps[];
  posts: {
    media_count: number;
    data: PostProps[];
    paging: {
      cursors: {
        after: string;
        before: string;
      };
      discovery: boolean;
    };
  };
  post: PostProps;
  access_token: string;
  fb_connect: boolean;
  user_fb_connect: string;
  searchPost: boolean;
}

export const UserInstagramContext = React.createContext<UserContextProps>(
  {} as UserContextProps,
);

// eslint-disable-next-line
export const UserInstagramProvider = (props: any) => {
  const [userInstagram, setUserInstagram] = useState<UserProps>({
    username: '',
    allowConnect: false,
    id_facebook: 0,
    id: 0,
    ig_id: 0,
    isVerified: false,
    checkoutDay: '',
    followers_count: 0,
    follows_count: 0,
    profile_pic_url: '',
    profile_pic_url_base64: '',
    access_token: '',
    searchPost: false,
    fb_connect: false,
    user_fb_connect: '',
    email: '',
    accounts_connected: [],
    setup: {
      double_comments_allowed: false,
      draw_per_click: '1',
      hashtags: '',
      more_than_one_commentper_person: false,
      same_person_more_than_once_allowed: false,
    },
    payments: {
      id_card: 0,
      id_pix: 0,
      status: '',
      cardHash: '',
      method: '',
    },
    postsVip: [],
    posts: {
      media_count: 0,
      data: [],
      paging: {
        cursors: {
          after: '',
          before: '',
        },
        discovery: false,
      },
    },
    post: {
      node: [],
      id: 0,
      ig_id: 0,
      permalink: '',
      media_url: '',
      media_type: '',
      media_url_base64: '',
      shortcode: '',
      comments_count: 0,
      isVip: false,
      linkVip: '',
      searchPost: false,
    },
    loading: {
      initialLoading: false,
      porcentloading: 0,
      comments_count: 0,
    },
    drawNumber: 0,
    winner: [],
  });

  const { children } = props;

  return (
    <UserInstagramContext.Provider value={{ setUserInstagram, userInstagram }}>
      {children}
    </UserInstagramContext.Provider>
  );
};
