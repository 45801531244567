import React from 'react';
import ReactDOM from 'react-dom';

import TagManager from 'react-gtm-module';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import App from './App';

datadogLogs.init({
  clientToken: 'puba0f7ccf75d0ea5f6619311b608bccb73',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
});

datadogRum.init({
  applicationId: '55e58cd2-ae7f-4cf6-a232-13946deae1b8',
  clientToken: 'pub1ff057b7ca01c7a437f666ff54767a89',
  site: 'us5.datadoghq.com',
  service: 'instasorteio',
  env: 'production',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

const tagManagerArgs = {
  gtmId: 'GTM-5XDNCGZ',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
