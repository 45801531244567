import { createTheme } from '@material-ui/core/styles';

const Theme = createTheme({
  typography: {
    fontFamily: ['Lato'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 56,

      fontWeight: 700,
    },
    h2: {
      fontSize: 48,
      fontWeight: 700,
    },
    h3: {
      fontSize: 40,
      fontWeight: 700,
    },
    h4: {
      fontSize: 26,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 32,
      },
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 24,
      },
    },
    h6: {
      lineHeight: 'normal',
      fontSize: 20,
      fontWeight: 700,
    },
    subtitle2: {
      lineHeight: 'normal',
    },
    subtitle1: {
      lineHeight: 'normal',
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      '@media (min-width:600px)': {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 12,
      '@media (min-width:600px)': {
        fontSize: 14,
      },
    },
  },
  palette: {
    text: {
      primary: 'rgba(35, 35, 35, 1)',
      secondary: '#424b6d',
    },
    primary: {
      // main: '#D62828',
      // light: '#F7575F',
      main: '#884DE0',
      light: '#B391E3',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#F2F0FE',
    },
    background: {
      paper: '#FFFFFF',
    },
    grey: {
      '100': '#242424',
      '300': '#6b7390',
      // '300': '#7780A0',
      '500': 'rgb(75 87 130)',
      '400': '#bfbfbfcc',
      '600': '#EBECF4',
      '800': '#F8F8FC',
      '900': '#FDFDFF',
    },
    success: {
      main: '#6FCF97',
      light: '#f1faf5',
    },
    error: {
      main: '#FF2A7A',
      light: '#FFEFF4',
    },
    info: {
      main: '#2B93F2',
      dark: '#1877F2',
    },
    warning: {
      main: '#FFC24C',
      light: '#fff9ed',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: 'none',
        },
      },
    },
  },
});

export default Theme;
