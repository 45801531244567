import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Logo from 'assets/logo/PrincipalPositivaPurple.svg';

import {
  AuthProvider,
  AppProvider,
  StepProvider,
  LocalStorageProvider,
  UserFacebookProvider,
  UserInstagramProvider,
  NamesProvider,
} from './contexts';

import Theme from './styles/theme';
import useStyles from './styles/global';

const Footer = lazy(() => import('./components/Common/Page/Footer'));
const MainRoutes = lazy(() => import('./routes/Routes'));

const App: React.FC = () => {
  const classes = useStyles();

  const renderLoader = () => (
    <div className={classes.logoInit}>
      <img src={Logo} alt="Instasorteio" />
    </div>
  );

  return (
    <Suspense fallback={renderLoader()}>
      <div className={classes.paperStyles}>
        <ThemeProvider theme={Theme}>
          <LocalStorageProvider>
            <AuthProvider>
              <AppProvider>
                <StepProvider>
                  <NamesProvider>
                    <UserInstagramProvider>
                      <UserFacebookProvider>
                        <MainRoutes />
                        <Footer />
                        <CssBaseline />
                      </UserFacebookProvider>
                    </UserInstagramProvider>
                  </NamesProvider>
                </StepProvider>
              </AppProvider>
            </AuthProvider>
          </LocalStorageProvider>
        </ThemeProvider>
      </div>
    </Suspense>
  );
};
export default App;
