class Storage {
  get(key: string): any {
    const raw: any = window.localStorage.getItem(key);
    let json = {};
    try {
      json = raw ? JSON.parse(raw) : {};
    } catch (e) {
      json = {};
    }

    return json;
  }

  set(key: string, data: any = {}, replace: boolean): any {
    const newData = replace
      ? data
      : {
          ...this.get(key),
          ...data,
        };

    window.localStorage.setItem(key, JSON.stringify(newData));
    return newData;
  }

  remove(key: string): void {
    window.localStorage.removeItem(key);
  }
}

let instance = null;
export default (() => {
  if (!instance) {
    instance = new Storage();
  }
  return instance;
})();
