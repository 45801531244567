/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import storage from 'contexts/helpers/storage';
import languages from 'translate/languages';
import { useCookies } from 'react-cookie';

interface ToastProps {
  type: 'error' | 'success' | 'warning';
  title: string;
  message: string;
}
interface AppProps {
  language: 'pt' | 'en' | 'es';
  setLanguage: (newLang: 'pt' | 'en' | 'es') => void;
  getLanguage: () => any;
  getTherm: (key: string) => string;

  openModalFb: () => any;
  closeModalFb: () => any;
  isModalFacebook: boolean;

  openModalPostNotFound: () => any;
  closeModalPostNotFound: () => any;
  isModalPostNotFound: boolean;

  openModalURL: () => any;
  closeModalURL: () => any;
  isModalURLOpen: boolean;

  activeHearts: () => any;
  disactiveHearts: () => any;
  isStatusHearts: boolean;

  openModalNextWithoutLogin: () => any;
  closeModalNextWithoutLogin: () => any;
  isModalNextWithoutLogin: boolean;

  openModalHowDoesItWork: () => any;
  closeModalHowDoesItWork: () => any;
  isHowDoesItWorkModalOpen: boolean;

  openModalLogin: () => any;
  closeModalLogin: () => any;
  isLoginModalOpen: boolean;

  openModalExceededComments: () => any;
  closeModalExceededComments: () => any;
  isModalExceededComments: boolean;

  openModalShareWinner: () => any;
  closeModalShareWinner: () => any;
  isModalShareWinner: boolean;

  openModalSlowLoading: () => any;
  closeModalSlowLoading: () => any;
  isModalSlowLoading: boolean;

  openModalLoadingLink: () => any;
  closeModalLoadingLink: () => any;
  isModalLoadingLinkOpen: boolean;

  openModalTerms: () => any;
  closeModalTerms: () => any;
  isModalTermsOpen: boolean;

  openModalPrivacy: () => any;
  closeModalPrivacy: () => any;
  isModalPrivacyOpen: boolean;

  closeConfirmationModal: () => any;
  openConfirmationModal: () => any;
  isConfirmationModalOpen: boolean;

  openCommentsNotLoadedModal: () => any;
  closeCommentsNotLoadedModal: () => any;
  isModalCommentsNotLoaded: boolean;

  openExpiredDrawModal: () => any;
  closeExpiredDrawModal: () => any;
  isModalExpiredDrawOpen: boolean;

  openUserNotAllowedModal: () => any;
  closeUserNotAllowedModal: () => any;
  isModalUserNotAllowedOpen: boolean;

  handleOpenDigitalInfluencerModal: () => any;
  handleCloseDigitalInfluencerModal: () => any;
  isModalDigitalInfluencerOpen: boolean;

  isToastOpen: boolean;
  handleOpenToast: () => any;
  handleCloseToast: () => any;
  toastContent: ToastProps;
  setToastContent: any;

  getLanguageSet: () => any;
}

export const AppContext = React.createContext<AppProps>({} as AppProps);

// eslint-disable-next-line
export const AppProvider = (props: any) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'instasorteioLanguage',
  ]);
  const [isModalFacebook, setIsModalFacebookOpen] = useState(false);
  const [isModalPostNotFound, setIsModalPostNotFoundOpen] = useState(false);
  const [isModalNextWithoutLogin, setIsModalNextWithoutLogin] = useState(false);
  const [isModalExceededComments, setIsModalExceededComments] = useState(false);
  const [isModalCommentsNotLoaded, setIsModalCommentsNotLoaded] =
    useState(false);
  const [isModalShareWinner, setIsModalShareWinner] = useState(false);
  const [isModalSlowLoading, setIsSlowLoadingModalOpen] = useState(false);
  const [isModalLoadingLinkOpen, setIsLoadingLinkModalOpen] = useState(false);
  const [isModalExpiredDrawOpen, setIsExpiredDrawModalOpen] = useState(false);
  const [isModalUserNotAllowedOpen, setIsUserNotAllowedModalOpen] =
    useState(false);
  const [isModalTermsOpen, setisModalTermsOpen] = useState(false);
  const [isModalPrivacyOpen, setIsModalPrivacyOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isHowDoesItWorkModalOpen, setIsHowDoesItWorkModalOpen] =
    useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isStatusHearts, setIsStatusHearts] = useState(false);
  const [isModalURLOpen, setIsModalURLOpen] = useState(false);
  const [isModalDigitalInfluencerOpen, setIsModalDigitalInfluencerOpen] =
    useState(false);

  const storageKey = '@instasorteio:language';

  const [isToastOpen, setIsToastOpen] = useState(false);

  const [toastContent, setToastContent] = useState<ToastProps>({
    type: 'error',
    message: '',
    title: '',
  });

  const pathLang = window.location.pathname.split('/')[1];

  const [language, setCurrLanguage] = useState<'pt' | 'en' | 'es'>(
    pathLang
      ? pathLang === 'pt' || pathLang === 'en' || pathLang === 'es'
        ? pathLang
        : 'pt'
      : cookies.instasorteioLanguage || 'pt',
  );

  function setLanguage(newLang: 'pt' | 'en' | 'es'): void {
    storage.set(storageKey, { language: newLang }, false);
    removeCookie('instasorteioLanguage');
    setCookie('instasorteioLanguage', newLang);
    setCurrLanguage(newLang);
  }

  function getLanguageSet() {
    return language;
  }

  function getLanguage(): any {
    return languages[language];
  }

  function getTherm(key: string): string {
    return getLanguage()[key] || '';
  }

  function openModalURL() {
    setIsModalURLOpen(true);
  }
  function closeModalURL() {
    setIsModalURLOpen(false);
  }

  function handleCloseDigitalInfluencerModal() {
    setIsModalDigitalInfluencerOpen(false);
  }

  function handleOpenDigitalInfluencerModal() {
    setIsModalDigitalInfluencerOpen(true);
  }

  function closeConfirmationModal() {
    setIsConfirmationModalOpen(false);
  }

  function openConfirmationModal() {
    setIsConfirmationModalOpen(true);
  }

  function openModalTerms() {
    return setisModalTermsOpen(true);
  }
  function closeModalTerms() {
    return setisModalTermsOpen(false);
  }
  function openExpiredDrawModal() {
    return setIsExpiredDrawModalOpen(true);
  }
  function closeExpiredDrawModal() {
    return setIsExpiredDrawModalOpen(false);
  }

  function openUserNotAllowedModal() {
    return setIsUserNotAllowedModalOpen(true);
  }
  function closeUserNotAllowedModal() {
    return setIsUserNotAllowedModalOpen(false);
  }

  function openModalPrivacy() {
    return setIsModalPrivacyOpen(true);
  }
  function closeModalPrivacy() {
    return setIsModalPrivacyOpen(false);
  }
  function openModalFb() {
    return setIsModalFacebookOpen(true);
  }
  function openModalPostNotFound() {
    return setIsModalPostNotFoundOpen(true);
  }

  function closeModalFb() {
    return setIsModalFacebookOpen(false);
  }

  function closeModalPostNotFound() {
    return setIsModalPostNotFoundOpen(false);
  }

  function openModalNextWithoutLogin() {
    return setIsModalNextWithoutLogin(true);
  }

  function closeModalNextWithoutLogin() {
    return setIsModalNextWithoutLogin(false);
  }

  function openModalExceededComments() {
    return setIsModalExceededComments(true);
  }

  function closeModalExceededComments() {
    return setIsModalExceededComments(false);
  }

  function openModalShareWinner() {
    return setIsModalShareWinner(true);
  }

  function closeModalShareWinner() {
    return setIsModalShareWinner(false);
  }

  function openModalSlowLoading() {
    return setIsSlowLoadingModalOpen(true);
  }

  function closeModalSlowLoading() {
    return setIsSlowLoadingModalOpen(false);
  }

  function openModalLoadingLink() {
    return setIsLoadingLinkModalOpen(true);
  }

  function closeModalLoadingLink() {
    return setIsLoadingLinkModalOpen(false);
  }

  function openModalHowDoesItWork() {
    return setIsHowDoesItWorkModalOpen(true);
  }
  function openModalLogin() {
    return setIsLoginModalOpen(!isLoginModalOpen);
  }

  function closeModalLogin() {
    return setIsLoginModalOpen(false);
  }

  function closeModalHowDoesItWork() {
    return setIsHowDoesItWorkModalOpen(false);
  }
  function openCommentsNotLoadedModal() {
    return setIsModalCommentsNotLoaded(true);
  }

  function closeCommentsNotLoadedModal() {
    return setIsModalCommentsNotLoaded(false);
  }

  const handleCloseToast = () => {
    setIsToastOpen(false);
  };

  const handleOpenToast = () => {
    setIsToastOpen(true);
  };

  function activeHearts() {
    setIsStatusHearts(true);
  }

  function disactiveHearts() {
    setIsStatusHearts(false);
  }

  const { children } = props;
  return (
    <AppContext.Provider
      value={{
        language,
        setLanguage,
        getLanguage,
        getTherm,
        openModalFb,
        closeModalFb,
        isModalFacebook,
        openModalPostNotFound,
        closeModalPostNotFound,
        isModalPostNotFound,
        openModalNextWithoutLogin,
        closeModalNextWithoutLogin,
        isModalNextWithoutLogin,
        openModalExceededComments,
        closeModalExceededComments,
        isModalExceededComments,
        openModalShareWinner,
        closeModalShareWinner,
        isModalShareWinner,
        isModalSlowLoading,
        openModalSlowLoading,
        closeModalSlowLoading,
        openModalLoadingLink,
        closeModalLoadingLink,
        isModalLoadingLinkOpen,
        openModalTerms,
        closeModalTerms,
        isModalPrivacyOpen,
        isModalTermsOpen,
        openModalPrivacy,
        closeModalPrivacy,
        closeConfirmationModal,
        openConfirmationModal,
        isConfirmationModalOpen,
        openModalHowDoesItWork,
        closeModalHowDoesItWork,
        isHowDoesItWorkModalOpen,
        openModalLogin,
        closeModalLogin,
        isLoginModalOpen,
        openCommentsNotLoadedModal,
        closeCommentsNotLoadedModal,
        isModalCommentsNotLoaded,
        openExpiredDrawModal,
        closeExpiredDrawModal,
        isModalExpiredDrawOpen,
        openUserNotAllowedModal,
        closeUserNotAllowedModal,
        isModalUserNotAllowedOpen,
        activeHearts,
        disactiveHearts,
        isStatusHearts,
        openModalURL,
        closeModalURL,
        isModalURLOpen,
        getLanguageSet,
        isToastOpen,
        handleOpenToast,
        handleCloseToast,
        toastContent,
        setToastContent,
        handleOpenDigitalInfluencerModal,
        handleCloseDigitalInfluencerModal,
        isModalDigitalInfluencerOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
